/* styles.css */

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 600px;
  margin: 50px auto;
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.heading {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.input_1 {
  width: 97%;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.btn {
  display: block;
  width: calc(100% - 40px);
  padding: 10px;
  margin: 10px auto;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn:hover {
  background-color: #218838;
}



.container_2{
  display: flex;
 
}
.container .span{
  
  align-items: center;
  cursor: pointer;
}
